import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../../components/layout/Layout"
import Container from "../../../components/layout/Container"
import Type from "../../../components/typography/Type"
import SearchNav from "../../../components/search/SearchNav"
import JourneyCard from "../../../components/cards/JourneyCard"


import { Router } from "@reach/router"
import { getUser, isLoggedIn } from "../../../services/auth"
import LoginPrompt from "../../../components/app/loginPrompt"





export default function Pages( {data} ) {
return (
  <Layout>
  <Container>
  
  
  {isLoggedIn() ? (
  <>
  
  <div className="">
  {data.airtable.data.Journeys.map((array) => (
     <a href={array.data.Path}><Type type="p5">&lt; Back</Type></a>
   ))}</div>

  <div className="flex flex-col-reverse lg:flex-row space-x-0 lg:space-x-36">
    <div className="w-full lg:w-2/3 mt-12 lg:mt-0">
      <img
        src={data.airtable.data.FullS3}
        alt={data.airtable.data.Name}
        className="mb-12"
        />
    </div>
    <div className="w-full lg:w-1/3 ">
    {data.airtable.data.Journeys.map((array) => (
       <a href={array.data.Path}><Type type="p4" nopadding="yes">{array.data.Title}</Type></a>
     ))}
     {data.airtable.data.Organisations.map((array) => (
      <a href={array.data.Path}><Type type="p5">{array.data.Name}</Type></a>
      ))}
      <Type type="h2" nopadding="yes">{data.airtable.data.Name}</Type>
    </div>
  </div>
  </>
  ) : (
    <>
      <LoginPrompt/>
    </>
  )}
  

  </Container>
  </Layout>
  )
}


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Screens" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        Title
        FullS3
        Organisations {
          data {
            Name
            Path
          }
        }
        Journeys {
          data {
            JourneyName
            Path
            Title
            OrganisationTxt
          }
        }
      }
    }
  }
`
